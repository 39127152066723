import { convert } from "./currencyConvertion";

export  function earningConverter({ earnings, base, rates }) {
    const todayEarningsValue = earningsTotal({ earnings: earnings?.todayEarnings, base, rates });
    const weeklyEarningsValue = earningsTotal({ earnings: earnings?.weeklyEarnings, base, rates });
    const monthlyEarningsValue = earningsTotal({ earnings: earnings?.monthlyEarnings, base, rates });
    const totalEarningsValue = earningsTotal({ earnings: earnings?.totalEarnings, base, rates });
    return {
        todayEarningsValue,
        weeklyEarningsValue,
        monthlyEarningsValue,
        totalEarningsValue
    };
}

export function earningsTotal({ earnings, base, rates }) {
    return earnings?.reduce(
        function (a, b) {
            if (b.currency != base) {
                return a + convert(base, rates, parseFloat(b.riderServiceFee), b.currency) + convert(base, rates, parseFloat(b.driverServiceFee), b.currency)
            } else {
                return a + parseFloat(b.riderServiceFee) + parseFloat(b.driverServiceFee)
            }
        }, 0
    ).toFixed(2);
}