import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { connect } from 'react-redux';
import cx from 'classnames';
import { graphql } from 'react-apollo';
import { compose } from 'redux';
import AdminDashboard from '../../../components/SiteAdmin/AdminDashboard/AdminDashboard'
import Loader from '../../../components/Common/Loader/Loader';
import { adminLogout } from '../../../actions/siteadmin/logout';
import { earningConverter } from '../../../helpers/earningConverter';
import getDashboardCount from './getDashboardCount.graphql';
import getAdminUser from './GetAdminUser.graphql';
import s from './Dashboard.css';
class Dashboard extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    getDashboardCount: PropTypes.object
  };

  render() {
    const { getDashboardCount: { loading, getDashboardCount: earnings }, getDashboardCount, getAdminUser, title, adminLogout, base, rates } = this.props;
    let todayEarnings = 0, weeklyEarnings = 0, monthlyEarnings = 0, totalEarnings = 0;
    if (earnings) {
      const earningData = earningConverter({ earnings, base, rates });
      todayEarnings = earningData?.todayEarningsValue;
      weeklyEarnings = earningData?.weeklyEarningsValue;
      monthlyEarnings = earningData?.monthlyEarningsValue;
      totalEarnings = earningData?.totalEarningsValue;
    }
    if (getAdminUser.getAdminUser === null) {
      adminLogout();
    }

    return (
      <Loader type={"page"} show={loading}>
        <div className={cx(s.root, 'bgBlack')}>
          <div className={s.container}>
            <AdminDashboard
              title={title}
              count={getDashboardCount}
              todayEarnings={todayEarnings}
              weeklyEarnings={weeklyEarnings}
              monthlyEarnings={monthlyEarnings}
              totalEarnings={totalEarnings}
            />
          </div>
        </div>
      </Loader>
    );
  }
}
const mapState = state => ({
  base: state.currency.base,
  rates: state.currency.rates,
})

const mapDispatch = {
  adminLogout
}

export default compose(
  withStyles(s),
  (connect(mapState, mapDispatch)),
  graphql(getDashboardCount, {
    name: 'getDashboardCount',
    options: {
      ssr: true,
      fetchPolicy: 'network-only'
    }
  }),
  graphql(getAdminUser,
    {
      name: 'getAdminUser',
      options: {
        ssr: true,
        fetchPolicy: 'network-only'
      }
    }
  ),
)(Dashboard);
