import {
  GET_ADMIN_USER_SUCCESS,
  CREATE_ADMIN_USER_SUCCESS,
  CREATE_ADMIN_USER_START,
  CREATE_ADMIN_USER_ERROR,
  CREATE_ADMIN_ROLES_START,
  CREATE_ADMIN_ROLES_SUCCESS,
  CREATE_ADMIN_ROLES_ERROR
} from '../constants';

export default function adminPrevileges(state = {}, action) {
  switch (action.type) {

    case CREATE_ADMIN_USER_START:
      return {
        ...state,
        createAdminUserLoading: action.payload.createAdminUserLoading
      };

    case CREATE_ADMIN_USER_SUCCESS:
      return {
        ...state,
        createAdminUserLoading: action.payload.createAdminUserLoading
      };

      case CREATE_ADMIN_USER_ERROR:
        return {
          ...state,
          createAdminUserLoading: action.payload.createAdminUserLoading
        };

        case CREATE_ADMIN_ROLES_START:
          return {
            ...state,
            createAdminRoleLoading: action.payload.createAdminRoleLoading
          };
    
        case CREATE_ADMIN_ROLES_SUCCESS:
          return {
            ...state,
            createAdminRoleLoading: action.payload.createAdminRoleLoading
          };
    
          case CREATE_ADMIN_ROLES_ERROR:
            return {
              ...state,
              createAdminRoleLoading: action.payload.createAdminRoleLoading
            };
      

    case GET_ADMIN_USER_SUCCESS:
      return {
        ...state,
        privileges: action.payload.adminPrivileges
      };

    default:
      return {
        ...state,
      };
  }
}
