import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import ReactGoogleMapLoader from "react-google-maps-loader";
import Geosuggest from 'react-geosuggest';
import DrawPolygonMap from './DrawPolygonMap';
import messages from '../../../locale/messages';
import { googleMapAPI } from '../../../config';

import s from './GooglePolygonMap.css';
import bt from '../../../components/commonStyle.css';
import c from 'react-geosuggest/module/geosuggest.css';

class GooglePolygonMap extends React.Component {

  static defaultProps = {
    label: '',
    className: '',
    defaultValue: '',
    containerClassName: ''
  };

  constructor(props) {
    super(props);

    this.state = {
      noResult: false,
      lat: 37.0902,
      lng: -95.7129,
      sw_lat: 9.854550803628602,
      sw_lng: 80.12749270688475,
      ne_lat: 46.588831619427665,
      ne_lng: -120.63917188786621
    };
    this.handleSelectSuggest = this.handleSelectSuggest.bind(this);
  }

  handleSelectSuggest(data) {
    let northEast, southWest, sw_lat, sw_lng, ne_lat, ne_lng;

     northEast = data?.gmaps?.geometry?.viewport?.getNorthEast();
     southWest = data?.gmaps?.geometry?.viewport?.getSouthWest();
     sw_lat = southWest?.lat();
     sw_lng = southWest?.lng();
     ne_lat = northEast?.lat();
     ne_lng = northEast?.lng();
  
    if (data) {
      this.setState({
        lat: data.location && data.location.lat,
        lng: data.location && data.location.lng,
        sw_lat,
        sw_lng,
        ne_lat,
        ne_lng
      })
    }
  }

  render() {
    const { label, className, defaultValue, containerClassName } = this.props;
    const { formName, fieldName, paths } = this.props;
    const { formatMessage } = this.props.intl;
    const { lat, lng, sw_lat, sw_lng, ne_lat, ne_lng } = this.state;

    return (
      <div>
        <label className={bt.labelText} >{formatMessage(messages.searchLocation)}</label>
        <ReactGoogleMapLoader
          params={{
            key: googleMapAPI,
            libraries: "geometry,drawing,places"
          }}
          render={googleMaps =>
            googleMaps && (
              <div>
                <Geosuggest
                  ref={el => this._geoSuggest = el}
                  placeholder={label ? label : formatMessage(messages.searchLocation)}
                  initialValue={defaultValue}
                  className={cx(s.geosuggest, containerClassName)}
                  inputClassName={cx('form-control', s.geosuggestInput, className)}
                  suggestsClassName={cx(s.geosuggest__suggests)}
                  suggestsHiddenClassName={cx(s.geosuggest__suggests_hidden)}
                  onSuggestSelect={this.handleSelectSuggest}
                  onSuggestNoResults={(value) => value && this.setState({ noResult: true })}
                  autoComplete={'off'}
                />
                <DrawPolygonMap
                  lat={lat}
                  lng={lng}
                  formName={formName}
                  fieldName={fieldName}
                  paths={paths}
                  sw_lat={sw_lat}
                  sw_lng={sw_lng}
                  ne_lat={ne_lat}
                  ne_lng={ne_lng}
                />
              </div>
            )
          }
        />
      </div>
    );
  }
}

export default injectIntl(withStyles(c, s, bt)(GooglePolygonMap));
